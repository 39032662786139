// import 'c3';
/**============================================================
*   [graph006]
*   description : g006グラフの処理を記述
============================================================*/
var graphId6 = "#g006";
$(document).ready(function () {
  var dataColorG006 = {
    data1: '#13C959',
    data2: '#FDB900',
    data3: '#FF009D',
    data4: '#FF009D',
    data5: '#FF009D',
  };
  if ($(graphId6).length > 0 && !((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && $(window).width() < 991))) {
    // Call Graph006 function
    Graph006(graphId6, dataG006, dataColorG006)
  }
});
function Graph006(id, dataG006, dataColorG006) {
  /*data of Graph006*/
  //Padding
  var paddingTop = 0
  paddingLeft = 10
  paddingRight = 0
  //Width & Height
  widthColumn = 42
  spaceColumn = 0.78 //distance between 2 columns
  heightGraph = (()=>{
    const g006h = $('.c-graph-006__option').outerHeight(true) - $('.c-graph-006__option li:first-child').outerHeight(true) + 29;
    return g006h;
  })();
  //padding axis
  paddingTopAxisX = 0
  paddingBottomAxisX = 0
  paddingLeftAxisX = 0
  paddingRightAxisX = 0
  var chart = c3.generate({
    bindto: id,
    data: {
      columns: dataG006,
      type: 'bar',
      colors: dataColorG006,
      labels: {
        show: true,
        // add "%" for column
        format: {
          data1: d3.format(''),
          data1: function (v, id, i, j) { return v + "%"; },
          data2: d3.format(''),
          data2: function (v, id, i, j) { return v + "%"; },
          data3: d3.format(''),
          data3: function (v, id, i, j) { return v + "%"; },
          data4: d3.format(''),
          data4: function (v, id, i, j) { return v + "%"; },
          data5: d3.format(''),
          data5: function (v, id, i, j) { return v + "%"; },
        }
      },
    },
    size: {
      height: heightGraph,
    },
    padding: {
      top: paddingTop,
      left: paddingLeft,
      right: paddingRight
    },
    grid: {
      lines: {
        front: false
      },
      x: {
        tick: {
          rotate: 75,
        },
        show: false,
      },
      y: {
        lines: [
          { value: 0 },
          { value: 10 },
          { value: 20 },
          { value: 30 },
          { value: 40 },
          { value: 50 },
          { value: 60 },
          { value: 70 },
          { value: 80 }
        ]
      }
    },
    axis: {
      rotated: true,
      x: {
        tick: {
          outer: false
        },
      },
      y: {
        tick: {
          outer: false
        },
        max: 80.1,
        min: 0,
        padding: { top: paddingTopAxisX, bottom: paddingBottomAxisX, left: paddingLeftAxisX, right: paddingRightAxisX },
      }
    },
    bar: {
      width: widthColumn,
      space: spaceColumn
    },
    tooltip: {
      show: false
    },
    legend: {
      show: false
    },
    interaction: {
      enabled: false
    }
  });

  window.matchMedia("print").addListener(function() {
    if(!$('body').hasClass("is-modal--white-print") && $(graphId6).is(":visible")){
      chart.resize();
    }
  })

}
