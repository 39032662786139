/**============================================================
 *   [a001]
 *   description : a001のページ追加の処理を記述
 ============================================================*/
$(document).ready(function () {
  // Recall Material css tabs with onShow function, resize chart on show tab
  var instance = M.Tabs.init(document.querySelector('.p-a001-second .c-tabs'), {
    onShow: function (ele) {
      setTimeout(function () {
        switch (ele.id) {
          case "a001-nps-score-day":
            $('#g003').data('c3-chart').resize({height: 230})
            $('#g004').data('c3-chart').resize({height: 230})
            $('#g003').css('max-height', '230px')
            $('#g003>svg').css('height', '230px')
            $('#g004').css('max-height', '230px')
            $('#g004>svg').css('height', '230px')
            break
          case "a001-nps-score-week":
            $('#g003-week').data('c3-chart').resize({height: 230})
            $('#g004-week').data('c3-chart').resize({height: 230})
            $('#g003-week').css('max-height', '230px')
            $('#g003-week>svg').css('height', '230px')
            $('#g004-week').css('max-height', '230px')
            $('#g004-week>svg').css('height', '230px')
            break
          case "a001-nps-score-month":
            $('#g003-month').data('c3-chart').resize({height: 230})
            $('#g004-month').data('c3-chart').resize({height: 230})
            $('#g003-month').css('max-height', '230px')
            $('#g003-month>svg').css('height', '230px')
            $('#g004-month').css('max-height', '230px')
            $('#g004-month>svg').css('height', '230px')
            break
        }
      }, 5)
    }
  })
})
