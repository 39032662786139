import Sortable from 'sortablejs';
document.addEventListener('DOMContentLoaded', function() {
  function list(){

    /**============================================================
    *   [list dragg]
    *   description : draggして入れ替えるリストの処理を記述
    ============================================================*/
    if(document.querySelector('.js-dragg-group')){
      document.querySelectorAll('.js-dragg-group').forEach((el, index) => {
        if (!Sortable.get(el)) {
          let sortable = Sortable.create(el, {
            group: 'drag-' + index,
            handle: '.js-dragg__ghandle',
            animation: 300
          });
        }
      })

    };

    /**============================================================
    *   [list collapsible]
    *   description : アコーディオンの処理を記述
    ============================================================*/
    let collapsibleElems = document.querySelectorAll('.collapsible');

    let collapsibleInstances = M.Collapsible.init(collapsibleElems, {
      inDuration: 200
    });

    /**============================================================
    *   [list selector]
    *   description : select[完了][下書き][終了]でカラーを変更する処理を記述
    ============================================================*/
    if($('.js-list-edit-state').length > 0){
      $('.js-list-edit-state').each(function(){
        if($(this).text() == '実施中'){
          $(this).addClass('is-active');
        }
        if($(this).text() == '終了'){
          $(this).addClass('is-end');
        }
      })
    }
  }
  list();

  var moLiEl = document.getElementById('js-modal__body');
  var moLiWEl = document.getElementById('js-modal--white__body');
  var moLi = new MutationObserver(list);
  moLi.observe(moLiEl, {childList: true});
  moLi.observe(moLiWEl, {childList: true});

  /**============================================================
  *   [list image selector]
  *   description : 画像アップロード時のサムネイル表示処理を記述
  ============================================================*/
  $(document).on('change', '.js-choice-list-image', function () {
    var $parentDiv = $(this).closest('.js-list-image');
    var $uploadBtn = $(this).closest('.c-list-drag__image-upload');
    var $confirmBtn = $parentDiv.find('.js-modal__button');
    var reviewImageSrc = ''

    var arrayFileUrl = $(this).val().split('\\');
    var fileName = arrayFileUrl[arrayFileUrl.length - 1];
    if (fileName == '') {
      return false;
    } else {
      // Set review src image
      reviewImageSrc = '#/' + fileName;
      var htmlContent = '<img src="' + reviewImageSrc + '" width="24" style="border: 1px solid #707070;">';
      $confirmBtn.html(htmlContent);
      $confirmBtn.removeClass('d-none');
      $uploadBtn.addClass('d-none');
    }
  });
});
