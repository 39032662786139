/**============================================================
*   [graph-nps-score]
*   description : NPSスコア推移グラフ描画用の処理を記述
============================================================*/
(function () {

  if(document.getElementById('a001-nps')){

    var NPSSCOREGRAPH = {

      //スコア要素の配列
      scoreGridArr : document.getElementById("p-a001-nps__score").children,
      //グラフのグリッド要素の配列
      graphGridArr : document.getElementById("p-a001-nps__graph__grid").children,
      //ラインcanvas要素
      graphLineCanvas : document.getElementById("p-a001-nps__graph__line"),

      //グラフ全幅の設定（1カラム最低60px幅）
      containerWidth: function(){
        document.querySelector(".p-a001-nps__body").style.width = this.scoreGridArr.length * 60 + 'px';
      },

      //スコア（数値）の配列
      scoreArr: (function() {
        var score = document.getElementById("p-a001-nps__score").children,
        arr = [];
        [].map.call(score, function(el) {
          var text = el.childNodes.item(0).nodeValue.trim();
          text = parseInt(text);
          arr.push(text);
        });
        return arr;
      })(),

      //グラフのY値の算出
      posY: function(score) {
        if(Math.sign(score) === 1){
          var pos = 100 - Math.abs(score);
        }else if(Math.sign(score) === 0){
          var pos = 100;
        }else{
          var pos = 100 + Math.abs(score);
        }
        return pos;
      },

      //グラフ描画
      drawGraph: (el,datas)=> {
        let canvasEl = el;
        let line_opts = {
          color: 'white',
          width: 2
        };
        let measure_opts = {
          color: 'rgba(255,255,255,0.5)',
          width: 1
        };
        let canvasWidth = () => {
          var graphContainerW = document.getElementById("a001-nps").clientWidth;
          canvasEl.width = (graphContainerW > (datas.length * 60)) ? graphContainerW : datas.length * 60;
        };
        let setGraph = () => {
          canvasWidth();
          draw(canvasEl, datas, line_opts);
        }

        //canvas
        let draw = (canvas_obj, datas, line_opts) => {
          let canvas = canvas_obj.getContext('2d');
          // line
          let pos1 = {
            x: (canvas_obj.width / datas.length / 2),
            y: 0
          };
          let pos2 = {
            x: (canvas_obj.width / datas.length / 2),
            y: 0
          };
          // dot
          let dotPos = {
            x: (canvas_obj.width / datas.length / 2 - 4),
            y: 0
          };
          // 1カラムの大きさ
          let dataWH = {
            w: canvas_obj.width / datas.length,
            h: canvas_obj.height
          };

          //背景目盛り描画
          function measure(context, measureLength, measure_opts){
            for(var i = 0; i < measureLength; i++){
              //真ん中と最後の線以外を描画
              if(![3,7].includes(i)){
                var measurePosY = (canvas_obj.height - 8) / 8 * (i + 1) + 4;//ドットはみだし分の高さを引いてから割って半分ずらす
                context.save();
                context.strokeStyle = measure_opts.color;
                context.lineWidth = measure_opts.width;
                context.beginPath();
                context.moveTo(0, measurePosY);
                context.lineTo(canvas_obj.width, measurePosY);
                context.stroke();
                context.restore();
              }
            }
          }

          //ライン描画
          function line(context, data, key, dataWH, line_opts){
            context.save();
            context.strokeStyle = line_opts.color;
            context.lineWidth = line_opts.width;
            context.beginPath();
            pos1.y = NPSSCOREGRAPH.posY(data) + 4;
            context.moveTo(pos1.x, pos1.y);
            pos2.x += dataWH.w;
            pos2.y =(NPSSCOREGRAPH.scoreArr[key + 1] !== 'undefined') ? NPSSCOREGRAPH.posY(NPSSCOREGRAPH.scoreArr[key + 1]) + 4 : 4;
            //直線版
            context.lineTo(pos2.x, pos2.y);
            //ベジェ版
            // context.bezierCurveTo((pos2.x + dataWH.w / 2 - dataWH.w), pos1.y, (pos2.x + dataWH.w / 2 - dataWH.w), pos2.y, pos2.x, pos2.y);
            context.stroke();
            //pos1更新
            pos1.x = pos2.x;
            context.restore();
          }

          //ドット描画
          function dot(context, data, key, dataWH){
            var dotColor = (Math.sign(data) === 1) ? '#1375C9' : (Math.sign(data) === 0) ? '#DBDBDB' : '#C9003F'
            context.save();
            dotPos.y = NPSSCOREGRAPH.posY(data);
            context.fillStyle = dotColor;
            context.fillRect(dotPos.x, dotPos.y, 8, 8);
            dotPos.x += dataWH.w;
            dotPos.y =(NPSSCOREGRAPH.scoreArr[key + 1] !== 'undefined') ? NPSSCOREGRAPH.posY(NPSSCOREGRAPH.scoreArr[key + 1]) : 0;
            context.restore();
          }

          //背景目盛り（canvas, 分割数, 目盛りのオプション）
          measure(canvas, 8, measure_opts);

          //ライン
          datas.forEach((data, key) => { if(key != key.length){ line(canvas, data, key, dataWH, line_opts)} });

          //ドット
          datas.forEach((data, key) => dot(canvas, data, key, dataWH));
        }
        setGraph();
        window.addEventListener('resize', () => setGraph(), false );
      }
    };

    //グラフ全幅の設定
    NPSSCOREGRAPH.containerWidth();

    //グラフ描画
    NPSSCOREGRAPH.drawGraph(NPSSCOREGRAPH.graphLineCanvas,NPSSCOREGRAPH.scoreArr);

    //数値ごとに各要素のスタイルを処理
    NPSSCOREGRAPH.scoreArr.forEach((score, $key) => {

      //[スコア文字色] 50以上、-50以下は反転
      if(Math.abs(score) >= 50) NPSSCOREGRAPH.scoreGridArr[$key].style.color = '#ffffff';

      // グリッドの背景色設定
      if(Math.sign(score) === 1){
        //[スコアがプラスの場合]
        NPSSCOREGRAPH.scoreGridArr[$key].style.backgroundColor = 'rgba(19,117,201,' + Math.abs(score) / 100 + ')';
        NPSSCOREGRAPH.graphGridArr[$key].style.backgroundColor = 'rgba(19,117,201,' + Math.abs(score) / 2 / 100 + ')';

      }else if(Math.sign(score) === 0){
        //[スコアが0の場合]
        NPSSCOREGRAPH.scoreGridArr[$key].style.backgroundColor = 'rgba(219,219,219,1)';
        NPSSCOREGRAPH.graphGridArr[$key].style.backgroundColor = 'rgba(219,219,219,0.5)';

      }else{
        //[スコアがマイナスの場合]
        NPSSCOREGRAPH.scoreGridArr[$key].style.backgroundColor = 'rgba(201,0,63,' + Math.abs(score) / 100 + ')';
        NPSSCOREGRAPH.graphGridArr[$key].style.backgroundColor = 'rgba(201,0,63,' + Math.abs(score) / 2 / 100 + ')';
      }
    });
  }

})();
