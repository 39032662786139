/**============================================================
*   [alert]
*   description : alertのcloseボタンの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  if(document.querySelector('.c-alert-baloon__close-btn')){
    let compoAlert = {
      hideAlert: (e)=>{
        let parent = $(e.target).closest('.c-alert-baloon');
        // let parent = e.target.parentNode;

        // if(parent.classList.contains("c-alert-baloon")){
          parent.removeClass('is-show');
        // }
      }
    }
    document.querySelectorAll('.c-alert-baloon__close-btn').forEach(function (button) {
      button.addEventListener('click', compoAlert.hideAlert);
    })

  };
});
