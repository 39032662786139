/**============================================================
 *   [graph005]
 *   description : g005散布図の処理を記述
 ============================================================*/
const graphId5 = '#g005'
let Grraph005 = (function () {
  "use strict"
  const margin = {top: 70, right: 10, bottom: 100, left: 175}
  const width = 2000 - margin.left - margin.right
  const height = 570 - margin.top - margin.bottom
  const iconAlertCircle = 'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z'
  const fullWidth = width + margin.left + margin.right
  const fullHeight = height + margin.top + margin.bottom
  const widthTooltip = 345
  let classPos = ''
  let aspect = 1
  let xPos, yPos, xPosPecent, yPosPecent, svg
  const color = d3.scaleOrdinal()
    .domain(["blue", "orange"])
    .range(["#0CD9E2", "#FF9300"])

  /**
   * Scale svg on window resize
   * @param svg - Responsive CSV
   */
  let responsivefy = function (svg) {
    if(!svg.node()){
      return false
    }
    // get container + svg aspect ratio
    var container = d3.select(svg.node().parentNode),
      width = parseInt(svg.style("width")),
      height = parseInt(svg.style("height")),
      aspect = width / height

    // add viewBox and preserveAspectRatio properties,
    // and call resize so that svg resizes on inital page load
    svg
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("preserveAspectRatio", "xMinYMid")
      .call(resize)

    // to register multiple listeners for same event type,
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on("resize." + container.attr("id"), resize)

    // get width of container and resize svg to fit it
    function resize() {
      var targetWidth = parseInt(container.style("width"))
      svg.attr("width", targetWidth)
      svg.attr("height", Math.round(targetWidth / aspect))
    }
  }

  /**
   * 引数 precision は正の値 1 であれば小数第一未満を四捨五入、-1 であれば十の位未満を四捨五
   * @param number
   * @param precision
   * @returns {*}
   */
  let roundNum = function (number, precision) {
    var shift = function (number, precision, reverseShift) {
      if (reverseShift) {
        precision = -precision
      }
      var numArray = ("" + number).split("e")
      return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision))
    }
    return shift(Math.round(shift(number, precision, false)), precision, true)
  }

  /**
   * function draw graph
   */
  let drawGraphPoint = function () {

    svg = d3.select(graphId5)
      .append('svg')
      .attr('class', 'svgBackground')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .call(responsivefy)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')
    svg.append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr('width', width + 1)
      .attr('height', height + 1)
      .attr('fill', '#F4F1EC')
      .attr('stroke', '#707070')

    //Add grid X axis with out label
    yRangeG005 = (typeof yRangeG005 !== 'undefined' && yRangeG005.length >= 2) ? yRangeG005 : [0, 0]
    const yScale = d3.scaleLinear()
      .domain(yRangeG005)
      .range([height, 0])

    const yAxis = d3.axisLeft(yScale)
      .ticks(11)
      .tickFormat("")
      .tickSize(width)
    // .tickValues([0, 100]);

    // add the axis by passing it into d3.call (and wrap it in a <g>)
    svg.append('g')
      .attr('transform', `translate(${width}, 0)`)
      .attr('class', 'axis')
      .call(yAxis).select(".domain").remove()

    //Add Main grid X axis with out label
    //-----------------------------------
    const yAxisMain = d3.axisLeft(yScale)
      .ticks(10)
      .tickSize(width)
      .tickValues(yRangePointG005)
      .tickFormat(d3.format(""))
      .tickPadding(10)

    // add the Main axis by passing it into d3.call (and wrap it in a <g>)
    svg.append('g')
      .attr('transform', `translate(${width}, 0)`).attr('class', 'main-axis').call(yAxisMain).select(".domain").remove()

    //Add Main grid Y axis with out label
    //-----------------------------------

    //Add grid Y axis with out label
    xRangeG005 = (typeof xRangeG005 !== 'undefined' && xRangeG005.length >= 2) ? xRangeG005 : [0,0]
    const xScale = d3.scaleLinear()
      .domain(xRangeG005)
      .range([0, width])

    const xAxis = d3.axisBottom(xScale)
      .ticks(30)
      .tickFormat('')
      .tickSize(height)
      .tickPadding(10)

    svg.append('g')
      .attr('class', 'axis')
      .call(xAxis).select(".domain").remove()

    //Add Main grid Y axis with out label
    const xAxisMain = d3.axisBottom(xScale)
      .ticks(30)
      .tickSize(height)
      .tickFormat(d3.format(".0%"))
      .tickPadding(15)
      .tickValues(xRangePointG005)

    const xAxisMainGroup = svg.append('g')
      .attr('class', 'main-axis 1123')
      .call(xAxisMain)

    xAxisMainGroup.select('.domain').remove()
    xAxisMainGroup.select('.tick:nth-child(1) text').attr('x', 15)
    xAxisMainGroup.select('.tick:nth-child(3) text').attr('x', -20)

    // Add TOP LEFT label background:
    svg.append('rect')
      .attr("x", 1)
      .attr("y", 1)
      .attr('width', 210)
      .attr('height', 50)
      .attr('class', 'label-sub-background')

    // Add X axis label:
    svg.append("text")
      .attr('class', 'label-sub')
      .attr("text-anchor", "center")
      .attr("x", 35)
      .attr("y", 33)
      .text("優先課題項目")

    // Add TOP RIGHT label background:
    svg.append('rect')
      .attr("x", width - 210)
      .attr("y", 1)
      .attr('width', 210)
      .attr('height', 50)
      .attr('class', 'label-sub-background')

    // Add X axis label:
    svg.append("text")
      .attr('class', 'label-sub')
      .attr("text-anchor", "center")
      .attr("x", width - 172)
      .attr("y", 33)
      .text("維持強化項目")

    // Add BOTTOM LEFT label background:
    svg.append('rect')
      .attr("x", 1)
      .attr("y", height - 50)
      .attr('width', 210)
      .attr('height', 50)
      .attr('class', 'label-sub-background')

    // Add X axis label:
    svg.append("text")
      .attr('class', 'label-sub')
      .attr("text-anchor", "center")
      .attr("x", 40)
      .attr("y", height - 15)
      .text("注意観察項目")

    // Add BOTTOM RIGHT label background:
    svg.append('rect')
      .attr("x", width - 210)
      .attr("y", height - 50)
      .attr('width', 210)
      .attr('height', 50)
      .attr('class', 'label-sub-background')

    // Add X axis label:
    svg.append("text")
      .attr('class', 'label-sub')
      .attr("text-anchor", "center")
      .attr("x", width - 170)
      .attr("y", height - 15)
      .text("基本維持項目")

    // Add X axis label background:
    svg.append('rect')
      .attr("x", 0)
      .attr("y", height + margin.top - 20)
      .attr('width', width)
      .attr('height', 40)
      .attr('class', 'labelX-background')
    // Add X axis label:
    svg.append("text")
      .attr('class', 'labelX')
      .attr("text-anchor", "end")
      .attr("fill", "#84817c")
      .attr("x", 950)
      .attr("y", 477)
      .text("←満足度→")

    // Add Y axis label background:
    svg.append('rect')
      .attr("x", -175)
      .attr("y", 0)
      .attr('width', 50)
      .attr('height', height)
      .attr('class', 'labelX-background')
    // Add Y axis label:
    svg.append("text")
      .attr('class', 'labelX')
      .attr("text-anchor", "start")
      .attr("transform", "rotate(-90)")
      .attr("x", -290)
      .attr("y", -145)
      .attr("fill", "#84817c")
      .text("← NPS相関係数 →")

    // icon bg
    svg.append("circle")
      .attr("cx", 1.2)
      .attr("cy", 1.2)
      .attr("r", 10)
      .attr("transform", "translate(" + (width - 194) + "," + -54 + ")")
      .style("fill", '#ffffff')

    // Add TOP RIGHT label info
    svg.append("path").attr("d", iconAlertCircle)
      .attr("class", "dot")
      .style('stroke', 'none')
      .attr("transform", "translate(" + (width - 207) + "," + -67 + ") scale(1.2)")
      .style("fill", color('orange'))

    // Add X axis label:
    svg.append("text")
      .attr('class', 'chart-info')
      .attr("text-anchor", "left")
      .attr("x", width - 170)
      .attr("y", -45)
      .attr("fill", "#84817c")
      .text("検定95%以下")

    // icon bg
    svg.append("circle")
      .attr("cx", 1.2)
      .attr("cy", 1.2)
      .attr("r", 10)
      .attr("transform", "translate(" + (width - 452) + "," + -54 + ")")
      .style("fill", '#ffffff')

    // Add TOP RIGHT label info
    svg.append("path").attr("d", iconAlertCircle)
      .attr("class", "dot")
      .style('stroke', 'none')
      .attr("transform", "translate(" + (width - 465) + "," + -67 + ") scale(1.2)")
      .style("fill", color('blue'))

    // Add X axis label:
    svg.append("text")
      .attr('class', 'chart-info')
      .attr("text-anchor", "left")
      .attr("x", width - 428)
      .attr("y", -45)
      .attr("fill", "#84817c")
      .text("検定95%以上")

    // Add Y AVG line
    const yAxisAvg = d3.axisLeft(yScale)
      .ticks(1)
      .tickSize(width)
      .tickValues([avgYG005])
      .tickFormat(d3.format(""))
      .tickPadding(10)

    // Add avg line to chart
    let yAxisAvgGroup = svg.append('g')
      .attr('transform', `translate(${width}, 0)`).attr('class', 'main-axis-avg').call(yAxisAvg)
    yAxisAvgGroup.select('.domain').remove()
    yAxisAvgGroup.select('line').attr('stroke-width','2')
    //Set position text Avg Y line
    yAxisAvgGroup.select('text').attr('transform','translate(55, -10)')

    //Add X AVG line
    const xAxisAvg = d3.axisBottom(xScale)
      .ticks(1)
      .tickSize(height)
      .tickFormat(d3.format(",.1%"))
      .tickPadding(15)
      .tickValues([avgXG005])

    let xAxisAvgGroup = svg.append('g')
      .attr('class', 'main-axis-avg').call(xAxisAvg)
    xAxisAvgGroup.select('.domain').remove()
    xAxisAvgGroup.select('line').attr('stroke-width','2')
    //Set position text Avg X line
    xAxisAvgGroup.select('text').attr('transform','translate(33, -30)')

    //Add tooltip wrap div
    let divWrapper = d3.select(graphId5).append("div").attr("class", "tooltip-wrapper")

    // Add dot data to new svg
    let svgDot = d3.select(graphId5 + ' .tooltip-wrapper')
      .append('svg')
      .attr('class', 'svgDot')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .call(responsivefy)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')

    //Add tooltip wrap div inner
    let divWrapperInner = d3.select(graphId5 + ' .tooltip-wrapper').append("div").attr("class", "tooltip-wrapper__inner")

    // Define the div for the tooltip
    let divTooltip = d3.select(graphId5 + " .tooltip-wrapper__inner").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)

    // Add dot bg
    svgDot.append('g')
      .selectAll("dot")
      .data(dataG005)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale(d.x)
      })
      .attr("cy", function (d) {
        return yScale(d.y)
      })
      .attr("r", 12)
      .style("fill", '#ffffff')

    // Add dot map
    svgDot.append('g')
      .selectAll("dot")
      .data(dataG005)
      .enter()
      .append("path")
      .attr("d", iconAlertCircle)
      .attr("class", "dot")
      .attr("transform", function (d) {
        return "translate(" + (xScale(d.x) - 16) + "," + (yScale(d.y) - 16) + "), scale(1.4)"
      })
      .style("fill", function (d) {
        return color(d.color)
      })
      .on("mouseover", function (d) {
        divTooltip.transition()
          .duration(200)
          .style("opacity", .9)
        yPos = yScale(d.y) < 0 ? yScale(d.y) * -1 : yScale(d.y)
        xPos = xScale(d.x)
        xPosPecent = roundNum(xPos / (width) * 100, 2)
        yPosPecent = roundNum(yPos / width * 100, 2)

        if (yPosPecent > 10.4) {
          yPosPecent = yPosPecent - 8
          classPos = 'bottom'
        } else {
          yPosPecent = yPosPecent - 1.9
          classPos = 'top'
        }
        if ((xPosPecent < 50 && xPosPecent > 20) || xPosPecent > 75) {
          xPosPecent = xPosPecent - 20 - 2
          classPos = classPos + '-left'
        } else {
          xPosPecent = xPosPecent + 1
            classPos = classPos + '-right'
        }
        divTooltip.html(`<div class="tooltip ${classPos}">
                  <div class="tooltip__bg"></div>
                  <p class="title">${d.name}</p>
                  <p class="description">${d.description}</p>
                  <div class="content">
                    <div class="content--left">
                      <span class="text">TOP2率</span>
                      <span class="number">${roundNum(d.x*100, 2)}%</span>
                    </div>
                    <div class="content--right">
                      <span class="text">相関係数</span>
                      <span class="number">${d.y}</span>
                    </div>
                  </div>
                </div>`)
          .attr("viewBox", "0 0 " + width + " " + height)
          .attr("preserveAspectRatio", "xMinYMid")
          .style("margin-left", (xPosPecent + 1) + "%")
          .style("margin-top", (yPosPecent) + "%")
          .style("width", (widthTooltip / 1830 * 100) + "%")
      })
      .on("mouseout", function (d) {
        divTooltip.transition()
          .duration(500)
          .style("opacity", 0)
      })
  }

  let init = function () {
    var graphElement = document.getElementById(graphId5.substring(1));
    if(graphElement){
      drawGraphPoint()
    }
  }

  return {
    init: init,
  }
})()
 // device is sp and width browser < 991 ==> return is sp, none draw graph when sp device
if ($(graphId5).length > 0 && !((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && $(window).width() < 991))) {
  Grraph005.init()
}
