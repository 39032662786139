/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  $('a.js-anchor[href^="#"]').on('click', function() {
    var href      = $(this).attr("href")
      ,target    = $(href == "#" || href == "#top" ? 'html' : href)
      ,speed     = href == "#top" ? 800 : 400
      ,fixedH    = ($(".l-header").css('position') === 'fixed') ? document.querySelector('.l-header').scrollHeight : 0
      ,position  = target.offset().top - fixedH;

    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});
