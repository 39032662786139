/**============================================================
*   [graph007]
*   description : g007グラフの処理を記述
============================================================*/
var graphId7 = "#g007";
$(document).ready(function () {
  var dataColorG007 = {
    good: '#13C959',
    normal: '#FDB900',
    bad: '#FF009D'
  };
	// device is sp and width browser < 991 ==> return is sp, none draw graph when sp device
	if ($(graphId7).length > 0 && !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && $(window).width() < 991)) {
			Graph007(graphId7, dataG007, dataColorG007);
	}
});
function Graph007(id, dataG007, dataColorG007) {
	/*data of Graph007*/
	//Padding
	var paddingLeft = 0
	paddingBottom = 0
	//Width & Height
	widthColumn = 30
	spaceColumn = 0.5 //distance between 2 columns
	heightGraph = 213
	//padding axis
	paddingTopAxisY = 0
	paddingBottomAxisY = 2
	//Label name
	labelGood = "推進者"
	labelNormal = "中立者"
	labelBad = "批判者"
	var chart = c3.generate({
		bindto: id,
		data: {
			json: dataG007,
			type: 'bar',
			colors: dataColorG007,
		},
		padding: {
			left: paddingLeft,
			bottom: paddingBottom
		},
		bar: {
			width: widthColumn,
			space: spaceColumn
		},
		size: {
			height: heightGraph
		},
		grid: {
			lines: {
				front: false
			},
			y: {
				show: true,
				lines: function () {
					/*draw line and text in graph*/
					return [
						{ value: 100, class: 'gridline gridlineTop', text: "100%", position: "start" },
						{ value: 50, class: 'gridline', text: "50%", position: "start" },
						{ value: 0, class: 'gridfoot2' },
					];
				},
			}
		},
		legend: {
			show: false,
		},
		axis: {
			x: {
				show: false,
				tick: {
					outer: false
				},
				type: "category",
			},
			y: {
				tick: {
					outer: false
				},
				max: 100,
				min: 0,
				padding: { top: paddingTopAxisY, bottom: paddingBottomAxisY },
			}
		},
		tooltip: {
			format: {
				name: function (name, ratio, id, index) {
					var labels = {
						good: labelGood,
						normal: labelNormal,
						bad: labelBad
					};
					return labels[name];
				}
			}
		}
	});
	window.matchMedia("print").addListener(function() {
		if(!$('body').hasClass("is-modal--white-print") && $(graphId7).is(":visible")){
			chart.resize();
		  }
	})

}

