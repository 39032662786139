var graphId1_1 = "#g001";
var graphId1_2 = "#g001_1";
var graphId2_1 = "#g002";
var graphId2_2 = "#g002_1";
$(document).ready(function () {
  // {array} data array value number answers [bad answer, normal answer, good answer]
    if ($(graphId1_1).length > 0) {
      Graph001(graphId1_1, dataG001_1);
    }
    if ($(graphId1_2).length > 0) {
      Graph001(graphId1_2, dataG001_2);
    }
    if ($(graphId2_1).length > 0) {
      Graph001(graphId2_1, dataG002_1, "small");
    }
    if ($(graphId2_2).length > 0) {
      Graph001(graphId2_2, dataG002_2, "small");
    }
});
/**
 * draw chart cricle
 * @param {string} id tag html id
 * @param {array} data array value number answers [bad answer, normal answer, good answer]
 * @param {number} styleGraph (graph001 or graph002)
 */
function Graph001(id, data, styleGraph) {
    /*Variables data of Graph001 and 002*/
    //width and height graph
    var width = 400,
    height = 400,
    //diameter of circle
    twoPi = 2 * Math.PI,
    progress = 0,
    pecentage = 0,
    formatTotalForm = d3.format("0");
    formatPercent = d3.format("+,.3n");
    let total = d3.sum(data);
    /*draw frame graph cricle*/
    var heightR = 12,
    accuPerc = 0;
    data = (total !== 0) ? data : [];

  if(styleGraph !== "small") {
    var border = d3.arc()
      .innerRadius(170)
      .outerRadius(168)
      .startAngle(0)
      .endAngle(90);
    var wrArc = d3.arc()
      .innerRadius(200)
      .outerRadius(188)
      .startAngle(0)
      .endAngle(90);
    var arc = d3.arc()
      .startAngle(0)
      .innerRadius(185)
      .outerRadius(175);
  } else {
    var border = d3.arc()
      .innerRadius(170)
      .outerRadius(169)
      .startAngle(0)
      .endAngle(90);
    var wrArc = d3.arc()
      .innerRadius(200)
      .outerRadius(190)
      .startAngle(0)
      .endAngle(90);
    var arc = d3.arc()
      .startAngle(0)
      .innerRadius(185)
      .outerRadius(177);
  }
  var sizeGraph = !styleGraph ? "93%" : "91%";
  // var sizeGraph = !styleGraph ? "100%" : "92%";
  var svg = d3.select(id).append("svg")
    .attr("width", sizeGraph)
    .attr("height", sizeGraph)
    .attr('viewBox','0 0 '+Math.min(width,height) +' '+Math.min(width,height) )
    .attr('preserveAspectRatio','xMinYMin')
    .append("g")
    .attr("transform", "translate(" + Math.min(width,height) / 2 + "," + Math.min(width,height) / 2 + ")");

  var svgBar = svg.append("svg")
    .attr("width", 338)
    .attr("height", heightR)
    .attr("class", "c-graph--bar")
    .append("g")
    .attr("transform", "translate(-170,-7)");
  var bar = svgBar.selectAll("g")
    .data(data)
    .enter().append("g")
  //render 3 line
  bar.append("rect")
    .attr("width", function (d) { return ((d / total) * 100) + "%"; })
    .attr("x", function (d) {
      let prevPerc = accuPerc;
      let currPerc = 100 * (d / total);
      accuPerc = accuPerc + currPerc;
      return prevPerc + "%";
    })
    .attr("height", heightR)
    .attr("class", function (d, i) { return "rect" + i });
  var meter = svg.append("g")
    .attr("class", "c-" + id);
  var wrapperTick = meter.append("path")
    .attr("d", wrArc)
    .attr("class", "tick-bg");
  var wrapperTick = meter.append("path")
    .attr("d", border)
    .attr("class", "border");

    /** graph001 */
  if(styleGraph !== "small") {
    svg.selectAll("path.tick")
      .data(d3.range(360))
      .enter()
      .append("path")
      .classed("tick", true)
      .attr("stroke", "#B0AEAD")
      .attr("d", d => {
        let s = d % 9 ? 0 : 10;
        d = d / 180 * Math.PI;
        let x = Math.cos(d);
        let y = Math.sin(d);
        let r = 200;
        return `M${x * r},${y * r}L${x * (r - s)},${y * (r - s)}`;
      });
    svg.selectAll("path.tick2")
      .data(d3.range(360))
      .enter()
      .append("path")
      .classed("tick2", true)
      .attr("stroke", "#707070")
      .attr("stroke-opacity", 0.6)
      .attr("d", d => {
        let s = d % 18 ? 0 : 10;
        d = d / 180 * Math.PI ;
        let x = Math.cos(d);
        let y = Math.sin(d);
        let r = 200;
        return `M${x * r},${y * r}L${x * (r - s)},${y * (r - s)}`;
      });
  } else {
    svg.selectAll("path.tick")
    .data(d3.range(360))
    .enter()
    .append("path")
    .classed("tick", true)
    .attr("stroke", "#737373")
    .attr("d", d => {
      let s = d % 18 ? 0 : 10;
      d = d / 180 * Math.PI;
      let x = Math.cos(d);
      let y = Math.sin(d);
      let r = 200;
      return `M${x * r},${y * r}L${x * (r - s)},${y * (r - s)}`;
    });
  }
  meter.append("path")
    .attr("class", "background")
    .attr("d", arc.endAngle(twoPi));
  var foreground = meter.append("path")
    .attr("class", "foreground");
  // position description text
  var posTopNPS;
  if(styleGraph !== "small") {
    posTopNPS = "-5.6em";
    posTopPerComple = "-0.7em";
    posTopNumAnswers = "2.7em";
    posTopNumTotal = "2.23em";
  } else {
    posTopNPS = "-4.9em";
    posTopPerComple = "-1em";
    posTopNumAnswers = "2.4em";
    posTopNumTotal = "2.4em";
  }
  // find pecent cricle progress
  pecentage = (data[0] > data[2]) ? -((data[0] - data[2]) / total) : ((data[2] - data[0]) / total);
  // draw description  NPS
  var textNPS = pecentage > 0 ? "NPS" : "NPSスコア";

  var description = meter.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "description")
    .attr("dy", posTopNPS)
    .attr("fill", "#707070")
    .text(textNPS);
  // draw description  回答数
  var description2 = meter.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "description")
    .attr("dy", posTopNumAnswers)
    .attr("fill", "#707070")
    .text("回答数");
  // draw total number
  var txtTotalForm = meter.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "percent-complete number-total")
    .attr("dy", posTopNumTotal)
    .attr("fill", "#383F41")
    .text(total);
  // draw percent  number
  var percentComplete = meter.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "percent-complete")
    .attr("fill", "#383F41")
    .attr("dy", posTopPerComple);
  /*end draw frame graph cricle*/

  // make color progress
  if (pecentage < 0) {
    $(id).find(".foreground").addClass("negative");
  }
  else {
    $(id).find(".foreground").removeClass("negative");
  }
  //handle animate graph move progress bar
  var i = d3.interpolate(progress, pecentage);
  var txtComplete;
  d3.select("body").selectAll(id).transition().duration(1000).tween("progress", function () {
    return function (t) {
      progress = i(t) || 0;
      txtComplete = (progress !== 0) ? formatPercent(progress * 100) : "-";
      foreground.attr("d", arc.endAngle(twoPi * progress));
      percentComplete.text(txtComplete);
      txtTotalForm.text(total);
    };
  });
  //animate count number total
  $(id).find(".number-total").prop("Counter",0).animate({
    Counter: total
  }, {
    duration: 1000,
    easing: "swing",
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
}
