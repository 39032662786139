/**============================================================
*   [modal]
*   description : デフォルトのmodal（黒背景）の処理を記述
============================================================*/

class modal {
  constructor(e,btn){
    this.$el = document.getElementById('js-modal');
    this.$body = document.getElementById('js-modal__body');
    this.isShow = false;
    this.href = '';
    this.$image = null;
  }
  modalToggle(e,btn){
    var currentmodal = this;
    if(modal.isShow){
      //close
      modal.isShow = false;
      currentmodal.$el.classList.remove('is-show');
      currentmodal.$body.innerHTML = '';
      $('#js-modal').find('.select2-container').remove();
      // document.getElementsByTagName("body")[0].classList.remove('u-ov--hidden');

      //transition終わりにコンテンツhidden
      currentmodal.$el.addEventListener('transitionend', (function(x) {
        return function f() {
          currentmodal.$el.removeEventListener('transitionend', f, false);
          currentmodal.$el.classList.remove('is-animated');
        }
      })(1), false);

      // e001-2内のサムネイルを削除（input type="file"の中身も削除）
      if (btn.classList.contains('js-modal__button--remove') && currentmodal.$image) {
        currentmodal.$image.getElementsByTagName('img')[0].setAttribute('src', '');
        currentmodal.$image.classList.add('d-none');
        currentmodal.$image.previousElementSibling.classList.remove('d-none');
        currentmodal.$image.previousElementSibling.getElementsByClassName('.js-choice-list-image').val = '';
        currentmodal.$image = null;
      }

    }else{
      //open
      modal.removeContentsHeight;
      modal.isShow = true;
      currentmodal.$el.classList.add('is-show','is-animated');
      // document.getElementsByTagName("body")[0].classList.add('u-ov--hidden');

      //data-href取得して表示
      modal.href = btn.getAttribute('data-href');
      if(modal.href){
        currentmodal.modalData(modal.href);
      }else{
        currentmodal.$body.innerHTML = '';
      }

      // e001-2内のサムネイルを内包するDOMを取得
      if (btn.classList.contains('c-list-drag__image-upload')) {
        currentmodal.$image = btn;
      }
    }
  };
  contentsHeight(){
    let h = (document.querySelector('.c-modal__body__inner')) ? document.querySelector('.c-modal__body__inner').scrollHeight : 0;
    if(h > (window.innerHeight - 40)) {
      this.$el.classList.add('is-fixed');
    }else{
      this.$el.classList.remove('is-fixed');
    }
  };
  removeContentsHeight(){
    modal.$el.classList.remove('is-fixed');
  };
  modalData(url){
    var currentmodal = this;
    $.ajax({
      type: "GET",
      url : url,
      dataType : 'html',
      success　: function(data){
        currentmodal.$body.innerHTML = data;
        currentmodal.contentsHeight();
      },
      error: function(data){
        currentmodal.contentsHeight();
      }
    });
  };
}
export default modal;

document.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', e => {
    var jsmodal = e.srcElement.closest('.js-modal__button');
    if(jsmodal) {
      var modalButton = new modal(jsmodal);
      modalButton.modalToggle(e,jsmodal);
    }
  }, true);
});

/**============================================================
*   [modal]
*   description : 右から出る白背景modalの処理を記述
============================================================*/
const modalWhite = {
  $el: document.getElementById('js-modal--white'),
  $body: document.getElementById('js-modal--white__body'),
  isShow: false,
  href: '',
  modalToggle: (e,btn)=>{
    if(modalWhite.isShow){
      modalWhite.isShow = false;
      modalWhite.$el.classList.remove('is-show');
      document.getElementsByTagName("body")[0].classList.remove('u-ov--hidden');
      modalWhite.$body.innerHTML = '';
      $('#js-modal--white').find('.select2-container').remove();

      //transition終わりにコンテンツhidden
      modalWhite.$el.addEventListener('transitionend', (function(x) {
        return function f() {
          modalWhite.$el.removeEventListener('transitionend', f, false);
          modalWhite.$el.classList.remove('is-animated');
        }
      })(1), false);

    }else{
      modalWhite.isShow = true;
      modalWhite.$el.classList.add('is-show','is-animated');
      document.getElementsByTagName("body")[0].classList.add('u-ov--hidden');

      //data-href取得して表示
      modalWhite.href = btn.getAttribute('data-href');

      if(modalWhite.href){
        modalWhite.modalData(modalWhite.href);
      }else{
        modalWhite.$body.innerHTML = '';
      }
    }
  },
  modalData: (url)=>{
    $.ajax({
      type: "GET",
      url : url,
      dataType : 'html',
      success　: function(data){
        modalWhite.$body.innerHTML = data;

        //モーダル下部のナビゲーションが存在したら中身の高さ変更
        if(document.querySelector('.c-modal__nav')){
          modalWhite.$el.classList.add('is-has-nav');
        }
      }
    });
  }
}
document.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', e => {
    var jswhitemodal = e.srcElement.closest('.js-modal--white__button');
    if(jswhitemodal) {
      modalWhite.modalToggle(e,jswhitemodal);
    }
  }, true);
});
