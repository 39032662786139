import Vue from "vue/dist/vue.esm.js";
import modal from "./modal";
/**============================================================
*   [gnav]
*   description : グローバルナビの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  if(document.getElementById('vue-gnav')){
    const gnav = new Vue({
      el: '#vue-gnav',
      data () {
        return {
          isGnavOpened: false,
          categoryNum: 0,
          isSP: (function(){
            var mql = window.matchMedia("(min-width: 992px)");
            if (mql.matches) {
              return false;
            }else{
              return true;
            }
          })()
        }
      },
      mounted: function(){
      },
      methods: {
        isCategorySelect: function(num,event) {
          let currentNum = this.categoryNum;
          this.setIsSP();

          if(event.type == "mouseenter"){
            if (!this.isSP) {
              if(currentNum == num){
                this.categoryNum = 0;
                this.isGnavOpened = false;
              }else{
                this.categoryNum = num;
                this.isGnavOpened = true;
              }
            }
          }else{
            if (this.isSP) {
              if(currentNum == num){
                this.categoryNum = 0;
                this.isGnavOpened = false;

              }else{
                this.categoryNum = num;
                this.isGnavOpened = true;

              }
            }
          }

        },
        isGnavClose: function(event){
          this.setIsSP();
          if (!this.isSP || event.type == "click") {
            //PC
            this.categoryNum = 0;
            this.isGnavOpened = false;
          }
        },
        setIsSP: function(){
          var mql = window.matchMedia("(min-width: 992px)");
          if (mql.matches) {
            this.isSP = false;
          }else{
            this.isSP = true;
          }
        }
      }
    })
  }
})
