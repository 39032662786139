import 'core-js';
import 'regenerator-runtime/runtime';

import 'materialize-css';
import 'moment/locale/ja';
import moment from 'moment';
import 'daterangepicker';
import 'select2';

//base
import './build/anchor.js';
import './build/print.js';
import './build/copy.js';
import './build/gnav.js';

//component
import './build/button.js';
import './build/modal.js';
import './build/alert.js';
import './build/list.js';
import './build/tab.js';

//form
import './build/form.js';

//graph
import './build/graph001-002.js';
import './build/graph003.js';
import './build/graph004.js';
import './build/graph005.js';
import './build/graph006.js';
import './build/graph007.js';
import './build/graph008.js';
import './build/graph-nps-score.js';
import './build/graph-anq.js';

//page
import './build/e001.js';
import './build/a001.js';
