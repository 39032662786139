/**============================================================
*   [graph004]
*   description : g004グラフの処理を記述
============================================================*/
//Variables data of Graph004
var graphId4 = "#g004";
var graphId4_w = "#g004-week";
var graphId4_m = "#g004-month";
var graphBuild_1, graphBuild_2, graphBuild_3;
var dataColorG004 = {
  good: '#13C959',
  normal: '#FDB900',
  bad: '#FF009D',
  column: 'transparent'
};
$(document).ready(function () {
  displayWindowSize()
  window.addEventListener("resize", displayWindowSize);
});
function displayWindowSize(){
  if($(window).width() > 991){
    if(!$(graphId4).data('c3-chart')){
      buildGraph();
    }
  }else{
    if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
      buildGraph();
    }
  }
}
function buildGraph() {
  if ($(graphId4).length > 0) {
    graphBuild_1 = Graph004(graphId4, dataG004_1, dataColorG004,'');
  }
  if ($(graphId4_w).length > 0) {
    graphBuild_2 = Graph004(graphId4_w, dataG004_2, dataColorG004,'g4-w');
  }
  if ($(graphId4_m).length > 0) {
    graphBuild_3 = Graph004(graphId4_m, dataG004_3, dataColorG004,'g4-m');
  }
  $(graphId4).data('c3-chart', graphBuild_1)
  $(graphId4_w).data('c3-chart', graphBuild_2)
  $(graphId4_m).data('c3-chart', graphBuild_3)

  handleGraphRezie();
}
// Resize graph
function handleGraphRezie() {
  // Resize graph on print
  window.matchMedia("print").addListener(function() {
    if(!$('body').hasClass("is-modal--white-print")){
      if($(graphId4).is(":visible")) {
        graphBuild_1.resize();
      }
      if($(graphId4_w).is(":visible")) {
        graphBuild_2.resize();
      }
      if($(graphId4_m).is(":visible")) {
        graphBuild_3.resize();
      }
    }
  })
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
function Graph004(id, dataG004, dataColorG004, g4Type) {
  /*data of Graph004*/
  //Padding
  var paddingTop = 20
  paddingLeft = 0,
  paddingRight = 0

  paddingBottom = 0
  //Width & Height
  widthColumn = 21
  heightGraph = 230
  //padding axis
  paddingTopAxisY = 0
  paddingBottomAxisY = 2
  //Label name
  labelGood = "推進者"
  labelNormal = "中立者"
  labelBad = "批判者"
  var jsonData = {
    good : dataG004.good,
    normal: dataG004.normal,
    bad: dataG004.bad,
    column: dataG004.good,
    x: dataG004.x
  }
  if(dataG004.axisXtype == 'timeseries'){
    formatTick = function (x) {
      if(isValidDate(x)){
        var tickLabelFormat = '';
        switch(dataG004.axisXLabelFormat) {
          case 'year/month':
            tickLabelFormat = x.getFullYear() + '/' + (x.getMonth() + 1);
            break;
          case 'month/day':
            tickLabelFormat = (x.getMonth() + 1) + '/' + x.getDate();
            break;
          case 'year':
            tickLabelFormat = x.getFullYear()
            break;
          default:
            x.getFullYear() + '/' + (x.getMonth() + 1);
        }
        return tickLabelFormat;
      }
    }
  } else {
    formatTick = false
  }
  var chart = c3.generate({
    bindto: id,
    data: {
      x: 'x',
      json: jsonData,
      types: {
        good: 'line',
        normal: 'line',
        bad: 'line',
        column: 'bar'
      },
      colors: dataColorG004,
    },
    size: {
      height: heightGraph
    },
    padding: {
      top: paddingTop,
      left: paddingLeft,
      bottom: paddingBottom
    },
    bar: {
      width: 0
    },

    grid: {
      lines: {
        front: false
      },
      y: {
        show: false,
        lines: function () {
          /*draw line and text in graph*/
          return [
            { value: 80 / 8 * 8, class: 'grid' },
            { value: 80 / 8 * 7, class: 'grid ', text: '80%', position: 'start' },
            { value: 80 / 8 * 6, class: 'grid', position: 'start' },
            { value: 80 / 8 * 5, class: 'grid' },
            { value: 80 / 8 * 4, class: 'gridline' },
            { value: 80 / 8 * 3, class: 'grid', text: '40%', position: 'start' },
            { value: 80 / 8 * 2, class: 'grid' },
            { value: 80 / 8 * 1, class: 'grid' },
            { value: 0, class: 'gridfoot2' }
          ];
        },
      }
    },
    legend: {
      show: false,
    },
    axis: {
      x: {
        tick: {
          outer: false,
          multiline: false,
          culling: {
            max: dataG004.axisXcullingMax
          },
          format: formatTick
        },
        type: dataG004.axisXtype,
        label: {
          text: '',
          position: "outer-left",
        }
      },
      y: {
        tick: {
          outer: false
        },
        max: 80,
        min: 0,
        padding: { top: paddingTopAxisY, bottom: paddingBottomAxisY },
        label: {
          text: '',
          position: 'inner-bottom',
        }
      }
    },
    point: {
      r: 4,
    },
    tooltip: {
      contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
        var $$ = this, config = $$.config,
          titleFormat = config.tooltip_format_title || defaultTitleFormat,
          nameFormat = config.tooltip_format_name || function (name) { return name; },
          valueFormat = config.tooltip_format_value || defaultValueFormat,
          text, i, title, value, name, bgcolor;
          var labels = {
            good: labelGood,
            normal: labelNormal,
            bad: labelBad
          };
        for (i = 0; i < d.length; i++) {
          if (! (d[i] && (d[i].value || d[i].value === 0))) { continue; }

          if (! text) {
            title = titleFormat ? titleFormat(d[i].x) : d[i].x;
            if(dataG004.axisXtype == 'timeseries'){
              title = d[i].x.getFullYear() + '/' + (d[i].x.getMonth() + 1) + '/' + d[i].x.getDate();
            }
            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
          }

          name = nameFormat(d[i].name);
          value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
          bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

          if(name != 'column'){
            text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
            text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + labels[name] + "</td>";
            text += "<td class='value'>" + value + "</td>";
            text += "</tr>";
          }
        }
        return text + "</table>";
      }

    },
    onrendered: function () {
      var graphWidth = parseInt(d3.select(id + ' .c3-event-rect').attr('width'))
      var axis = d3.selectAll("" + id + " .c3-axis-x");
      axis.attr("transform", "translate(0,0)");
      var tick = axis.selectAll(".tick");
      var tickText = axis.selectAll(".tick text");
      var tickShow = []
      tickText.filter(function(d, i) {
        if(d3.select(this).style('display') == 'block'){
          tickShow.push(d3.select(this))
        }
      });
      if(tickShow.length > 3) {
        // Detect First Tick
        var firstTransformValue = d3.select(tickShow[0].node().parentNode).attr('transform')
        var firstTranslateX = firstTransformValue.replace(/[^\d,.]/g, '').split(',')[0];

        var secondTransformValue = d3.select(tickShow[1].node().parentNode).attr('transform')
        var secondTranslateX = parseInt(secondTransformValue.replace(/[^\d,.]/g, '').split(',')[0])

        var lastTransformNode = d3.select(tickShow[tickShow.length / 2 - 1].node().parentNode)
        var lastTransformValue = lastTransformNode.attr('transform')
        var lastTranslateX = parseInt(lastTransformValue.replace(/[^\d,.]/g, '').split(',')[0])


        var nextLastTransformNode = d3.select(tickShow[tickShow.length / 2 - 2].node().parentNode).attr('transform')
        var nextLastTranslateX = parseInt(nextLastTransformNode.replace(/[^\d,.]/g, '').split(',')[0]);

        var tickWidth = tickShow[0].node().getBoundingClientRect().width / 2;
        var lastTickWidth = tickShow[tickShow.length / 2 - 1].node().getBoundingClientRect().width;
        var nextLastTickWidth = tickShow[tickShow.length / 2 - 2].node().getBoundingClientRect().width;
        // console.log(lastTranslateX, lastTickWidth, graphWidth, parseInt(lastTranslateX) + lastTickWidth)
        //Move first tick to x=0
        if (firstTranslateX < tickWidth)
          tickShow[0].select('tspan').attr('dx', (tickWidth - firstTranslateX))
        //Hide First tick when this tick over the next tick
        if (tickWidth > secondTranslateX)
          tickShow[0].select('tspan').attr('style', 'display: none')
        // Move the last tick to x = graph width
        if ((lastTranslateX + lastTickWidth /2) > graphWidth)
          lastTransformNode.attr('transform', 'translate(' + (graphWidth - lastTickWidth / 2) + ', 0)')
        //Hide Last tick when this tick over the next last tick
        if ((nextLastTranslateX + nextLastTickWidth / 2 + lastTickWidth) > graphWidth)
          tickShow[tickShow.length / 2 - 1].select('tspan').attr('style', 'display: none')
      }
      $('.c-graph-004 > p').css('visibility', 'visible')
    },
  });

  d3.select(id).insert('div', '.chart').attr('class', 'c-legend').selectAll('span')
    .data(['good', 'normal', 'bad'])
    .enter().append('span')
    .attr('data-id', function (id) { return id; })
    .html(function (id) { return id; })
    .on('mouseover', function (id) {
      chart.focus(id);
    })
    .on('mouseout', function (id) {
      chart.revert();
    })
    .on('click', function (id) {
      chart.toggle(id);
    });
  d3.select('' + id + ' span[data-id="good"]').html('<svg class="svg-face_good-dims"><use xlink:href="/assets/img/shared/sprite.symbol.svg#face_good"></use></svg>' + labelGood + '')
  d3.select('' + id + ' span[data-id="normal"]').html('<svg class="svg-face_normal-dims"><use xlink:href="/assets/img/shared/sprite.symbol.svg#face_normal"></use></svg>' + labelNormal + '')
  d3.select('' + id + ' span[data-id="bad"]').html('<svg class="svg-face_bad-dims"><use xlink:href="/assets/img/shared/sprite.symbol.svg#face_bad"></use></svg>' + labelBad + '')
  return chart;
}


