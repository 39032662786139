/**============================================================
 *   [form]
 *   description : formモジュール処理を記述
 ============================================================*/
$(document).ready(function(){
  var form = function (mutations) {
    let isObserver = (function(){
      if (mutations) {
        for (var mutation of mutations) {
          if (mutation.type === 'childList') {
            return true;
          }
        };
      }
      return false;
    })();
    /**============================================================
     *   [form F001]
     *   description : Dropdown list using Select2 library
     ============================================================*/
    $('.c-form__dropdown select').each(function(){
      var $selectInsertParent = $('body');
      if($(this).closest('.c-modal').length > 0){
        $selectInsertParent = $(this).closest('.c-modal');
      }
      $(this).select2({
        minimumResultsForSearch: Infinity,
        width: 'resolve',
        dropdownParent: $selectInsertParent
      });
    })
    /**============================================================
     *   [form F002, F003]
     *   description : date range picker, date single picker
     ============================================================*/
    var settingLocale = {
      format: 'YYYY/MM/DD',
      daysOfWeek: [ "日", "月", "火", "水", " 木", "金", "土" ],
      firstDay: 1,
      applyLabel: 'OK',
      cancelLabel: 'キャンセル'
    };
    $('input[name="daterange"]').each(function(){
      if($(this).attr('id') == 'p-a001__picker__term--sp'){
        $('#p-a001__picker__term--sp').daterangepicker({
          locale: settingLocale,
          parentEl: '#p-a001__picker__term--sp__container'
        });
      }else if($(this).attr('id') == 'p-a001__picker__term--pc'){
        $('#p-a001__picker__term--pc').daterangepicker({
          locale: settingLocale,
          parentEl: '#p-a001__picker__term--pc__container'
        });
      }else{
        $('input[name="daterange"]').daterangepicker({
          locale: settingLocale
        });
      }
    })
    $('input[name="datesingle"]').daterangepicker({
      singleDatePicker: true,
      locale: settingLocale,
      autoUpdateInput: false
    });

    $('input[name="daterange"], input[name="datesingle"]').on('showCalendar.daterangepicker', function(ev, picker) {
      // remove class css active in old td
      $('.c-form__clicle').removeClass('c-form__clicle');
      $('.table-condensed').find('.start-date, .end-date').each(function() {
        // add class css active in new td
        $(this).addClass('c-form__clicle');
        // change html td. make background cricle
        $(this).html('<span>' + $(this).html() + '</span>');
      });
    });
    // apply date into input
    $('input[name="daterange"]').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY/MM/DD') + ' - ' + picker.endDate.format('YYYY/MM/DD'));
    });
    // apply date into input
    $('input[name="datesingle"]').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });
    /**============================================================
     *   [form F007]
     *   description : select 2
     *   Data construct:
     *   - id: item id
     *   - text: full text, use to seach, not show to HTML
     *   - html: email
     *   - Title: name, show in tag
     ============================================================*/
    var data = [
      {
        goodmail: [
          {
            id: 0,
            text: 'StaffA - staffA@gmail.com',
            html: 'staffA@gmail.com',
            title: 'StaffA'
          }, {
            id: 1,
            text: 'StaffB - staffB@gmail.com',
            html: 'staffB@gmail.com',
            title: 'StaffB'
          }, {
            id: 2,
            text: 'StaffC - staffC@gmail.com',
            html: 'staffC@gmail.com',
            title: 'StaffC'
          },{
            id: 3,
            text: 'StaffD - staffD@gmail.com',
            html: 'staffD@gmail.com',
            title: 'StaffD'
          },{
            id: 4,
            text: 'StaffE - staffE@gmail.com',
            html: 'staffE@gmail.com',
            title: 'StaffC'
          }
        ],
        badmail: [
          {
            id: 0,
            text: 'StaffA - staffA@gmail.com',
            html: 'staffA@gmail.com',
            title: 'StaffA'
          }, {
            id: 1,
            text: 'StaffB - staffB@gmail.com',
            html: 'staffB@gmail.com',
            title: 'StaffB',
            selected: true
          }, {
            id: 2,
            text: 'StaffC - staffC@gmail.com',
            html: 'staffC@gmail.com',
            title: 'StaffC'
          },{
            id: 3,
            text: 'StaffD - staffD@gmail.com',
            html: 'staffD@gmail.com',
            title: 'StaffD'
          },{
            id: 4,
            text: 'StaffE - staffE@gmail.com',
            html: 'staffE@gmail.com',
            title: 'StaffC'
          }
        ],
        shop: [
          {
            id: 0,
            text: 'ShopA - shopA@gmail.com',
            html: 'shopA@gmail.com',
            title: 'ShopA'
          }, {
            id: 1,
            text: 'ShopB - shopB@gmail.com',
            html: 'shopB@gmail.com',
            title: 'ShopB'
          }, {
            id: 2,
            text: 'ShopC - shopC@gmail.com',
            html: 'shopA@gmail.com',
            title: 'ShopC'
          }
        ],
      }
    ];

    $('.js-multi-select').each(function(){
      const selectName = $(this).data('name');
      const $el = $(this);
      var $multiInsertParent = $('body');
      if($(this).closest('.c-modal').length > 0){
        $multiInsertParent = $(this).closest('.c-modal');
      }

      $el.select2({
        data: (function(){
          return data[0][selectName];
        })(),
        placeholder: $el.attr('data-title'),
        escapeMarkup: function(markup) {
          return markup;
        },
        templateResult: function(data) {
          return '<p class="title">'+data.title+'</p><p class="email">'+data.html+'</p>';
        },
        templateSelection: function(data) {
          return data.title;
        },
        dropdownParent: $multiInsertParent
      });

    })

    /**============================================================
     *   [form F004]
     *   description : date time picker
     ============================================================*/
    //create layout timepicker
    $('.timepicker').timepicker({
      onOpenStart: function(time) {
        $('.timepicker-dial .active').removeClass('active');
        var dt = new Date();
        var nowHour = dt.getHours() % 12;
        var nowMinus = dt.getMinutes() / 5 == 12 ? 0 : dt.getMinutes()/ 5 ;
        $('.timepicker-dial.timepicker-hours').children().eq(nowHour - 1).addClass('active');
        if(nowMinus === 0 || nowMinus === 1 || nowMinus === 2 || nowMinus === 3 || nowMinus === 4 || nowMinus === 5 || nowMinus === 6 || nowMinus === 7 || nowMinus === 8 || nowMinus === 9 || nowMinus === 10 || nowMinus === 11 || nowMinus === 12) {
          $('.timepicker-dial.timepicker-minutes').children().eq(nowMinus).addClass('active');
        }

      },
      onSelect: function(time, minus) {
        $('.timepicker-dial').find('.active').removeClass('active');
        $('.timepicker-dial.timepicker-hours').children().eq(time - 1).addClass('active');
        var nowMinus = minus / 5 == 12 ? 0 : minus / 5;
        if(nowMinus === 0 || nowMinus === 1 || nowMinus === 2 || nowMinus === 3 || nowMinus === 4 || nowMinus === 5 || nowMinus === 6 || nowMinus === 7 || nowMinus === 8 || nowMinus === 9 || nowMinus === 10 || nowMinus === 11 || nowMinus === 12) {
          $('.timepicker-dial.timepicker-minutes').children().eq(nowMinus).addClass('active');
        }
      }
    });
  }
  /**============================================================
   *   [form F012]
   *   description : choice picture
   ============================================================*/
  $(document).on('change', '.js-choice-image', function() {
    var $parentDiv = $(this).closest('.js-choice-f012');
    var $btn = $parentDiv.find('.btn-choice-file');
    var htmlCurent = $btn.html();
    var txtChange = '画像削除';
    var reviewImageSrc = ''

    var arrayFileUrl = $(this).val().split('\\');
    var fileName = arrayFileUrl[arrayFileUrl.length - 1];
    if(fileName == ''){
      return false;
    } else {
      // Set review src image
      reviewImageSrc = '#';
      var htmlContent = '<img src="' + reviewImageSrc + '" width ="30" style="border: 1px solid #707070;"><span class="txt-chose"></span>';
      $btn.html(htmlContent);
      $btn.css('position', 'relative');

      var $txt = $parentDiv.find('.txt-chose');
      $txt.html(txtChange);

      var $close = $parentDiv.find('.c-form__file__close');
      $close.css('display', 'block');

      var $image = $parentDiv.find('.js-choice-image');
      var $path = $parentDiv.find('.file-path');

      // Handle Clear input value on click [X] or [画像削除] button
      $([$close[0], $txt[0]]).bind('click', function(){
        // var parentDiv = $(this).closest('.js-choice-f012')
        $btn.css('position','inherit');
        $path.val('');
        $btn.html(htmlCurent);
        $image.val("");
        $close.css('display','none');
      });
    }
  });
  form(null);

  var moFEl = document.getElementById('js-modal__body');
  var moFWEl = document.getElementById('js-modal--white__body');
  var config = { attributes: false, childList: true, characterData: true };
  var moF = new MutationObserver(form);

  if (moFEl) {
    moF.observe(moFEl, config);
  }
  if (moFWEl) {
    moF.observe(moFWEl, config);
  }
});
