/**============================================================
*   [copy]
*   description : urlコピーの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  $(document).on('click', '.js-url-copy', clipURL);
  function clipURL() {
    $('body').append('<textarea id="currentURL" style="position:fixed;left:-100%;">'+location.href+'</textarea>');
    $('#currentURL').select();
    document.execCommand('copy');
    $('#currentURL').remove();
    alert("URLをコピーしました。");
  }
});
