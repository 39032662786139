/**============================================================
*   [tab]
*   description : tabコンテンツの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  if(document.querySelector('.c-tabs')){
    document.querySelectorAll('.c-tabs').forEach((el, index) => {
      let cTab = M.Tabs.init(el);
    })

  };
});
