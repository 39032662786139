
// import 'c8';
/**============================================================
*   [graph008]
*   description : g008グラフの処理を記述
============================================================*/
var graphId8_1 = "#g008_1";
var graphId8_2 = "#g008_2";
$(document).ready(function () {
  if ($(graphId8_1).length > 0) {
    // Call Graph008_1 function
    Graph008_1(graphId8_1,dataG008_1)
  }
  if ($(graphId8_2).length > 0) {
    // Call Graph008_2 function
    Graph008_2(graphId8_2,dataG008_2)
  }
});
function Graph008_1(id, data) {
    /*Data of Graph 008_1 */
    //Color
    var colorGood="#13C959"
    colorNormal ="#FDB900"
    colorBad = "#FF009D"
    //Padding
    paddingLeft = 0
    paddingTop = 30
    paddingBottom = 7
    //Width & Height
    widthColumn = 16
    heightGraph = 187
    //padding axis
    paddingLeftAxisX = 0.8
    paddingTopAxisY =  0,
    paddingBottomAxisY =  39,
    paddingLeftAxisY =  0,
    paddingRightAxisY =  0
    var colors = [colorGood, colorGood, colorNormal, colorNormal, colorBad, colorBad, colorBad, colorBad, colorBad, colorBad, colorBad];

    var chart = c3.generate({
      bindto: id,
      data: {
        x: "x",
        data: "data",
        json: data,

        types: {
          data: 'bar',
        },
        labels: {
          data: false,
        },
        color: function (color, d) {
          return colors[d.index];
        }
      },
      point: {
        show: false
      },
      padding: {
        left: paddingLeft,
        top: paddingTop,
        bottom: paddingBottom
      },
      interaction: {
        enabled: false
    },
      bar: {
        width: widthColumn,
      },
      size: {
        height: heightGraph
      },
      grid: {
        y: {
          lines: function () {
            return [
                /*draw line and text in graph*/
              { value: 50 / 10 * 10, class: 'gridline', text: "", position: "start" },
              { value: 50 / 10 * 9, class: 'grid' },
              { value: 50 / 10 * 8, class: 'grid' },
              { value: 50 / 10 * 7, class: 'grid' },
              { value: 50 / 10 * 6, class: 'grid' },
              { value: 50 / 10 * 5, class: 'gridline', text: "", position: "start" },
              { value: 50 / 10 * 4, class: 'grid' },
              { value: 50 / 10 * 3, class: 'grid' },
              { value: 50 / 10 * 2, class: 'grid' },
              { value: 50 / 10 * 1, class: 'grid', position: "start" },
              { value: 0, class: 'gridline' },
            ];
          },
        },
        lines: {
          front: false
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        show: false
      },
      axis: {
        x: {
          tick: {
            outer: false
          },
          type: "category",
          padding: { left: paddingLeftAxisX },
        },
        y: {
          tick: {
            outer: false
          },
          max: 50,
          min: 0,

          padding: {
            top: paddingTopAxisY,
            bottom: paddingBottomAxisY,
            left: paddingLeftAxisY,
            right: paddingRightAxisY
        }
        }
      },
      onrendered: function () {
        var axis = d3.selectAll(""+id+" .c3-axis-x");
        axis.selectAll(".c3-axis-x-label").attr("transform", "translate(10, 6)");
        var axisy = d3.selectAll(""+id+" .c3-axis-y");
        axisy.attr("transform", "translate(0,-55)");
        axisy.selectAll(".c3-axis-y-label").attr("transform", "translate(10, 6)");
        $(""+id+" svg .c3-chart-texts").attr("style","transform:translateY(-10px)")
        $(""+id+" svg .c3-chart-texts .data_label").remove();
        $(""+id+" svg .c3-chart-texts text").attr("class", "data_label").attr("y", "125");
      },
    });
    $(document).ready(function () {
      positiontext();
      changeNumberToPercent();
      $(window).resize(function () {
        positiontext()
      })
    });
    function positiontext() {
      var chartH = $(""+id+" .c3-event-rect").height()
      var transformYTop = -chartH - 97;
      var transformYbot = chartH - (chartH + 53.9);
      $("p.c-graph-008_title_top").attr('style', 'transform:translateY(' + transformYTop + 'px)')
      $("p.c-graph-008_title_bottom").attr('style', 'transform:translateY('+ transformYbot + 'px)')
      $(window).resize(function () {
        var chartH = $(""+id+" .c3-event-rect").height()
        var transformYTop = -chartH - 97;
        var transformYbot = chartH - (chartH +  53.9);
        $("p.c-graph-008_title_top").attr('style', 'transform:translateY(' + transformYTop + 'px)')
        $("p.c-graph-008_title_bottom").attr('style', 'transform:translateY(' + transformYbot + 'px)')
      })
    };
    /***
     * change value to percent data
     */
    function changeNumberToPercent() {
      var dataChart = chart.data.values('data');
      var total = 0;
      for (var i = 0; i < dataChart.length; i++) {
        total += dataChart[i];
      }
      var total_good = (dataChart[0] + dataChart[1]) || 0;
      var total_mormal = (dataChart[2] + dataChart[3]) || 0;
      var total_bad1 = (dataChart[4] + dataChart[5] + dataChart[6] + dataChart[7]) || 0;
      var total_bad2 = (dataChart[8] + dataChart[9] + dataChart[10]) || 0;
      //add total for total label
      $(".c-graph-008_list_total-1 .c-graph-008_list_total-1--child p").text(total_good + "票")
      $(".c-graph-008_list_total-1 .c-graph-008_list_total-2--child p").text(total_mormal + "票")
      $(".c-graph-008_list_total-1 .c-graph-008_list_total-3--child p").text(total_bad1 + "票")
      $(".c-graph-008_list_total-1 .c-graph-008_list_total-4--child p").text(total_bad2 + "票")
      // convert to array percent
      chart.load({
        columns: [
          ['data',
            round((dataChart[0] / total * 100), 2),
            round((dataChart[1] / total * 100), 2),
            round((dataChart[2] / total * 100), 2),
            round((dataChart[3] / total * 100), 2),
            round((dataChart[4] / total * 100), 2),
            round((dataChart[5] / total * 100), 2),
            round((dataChart[6] / total * 100), 2),
            round((dataChart[7] / total * 100), 2),
            round((dataChart[8] / total * 100), 2),
            round((dataChart[9] / total * 100), 2),
            round((dataChart[10] / total * 100), 2)]
        ],
      });
    }
    window.matchMedia("print").addListener(function() {
      if(!$('body').hasClass("is-modal--white-print")){
        chart.resize();
      }
    })
  }
function Graph008_2(id,data) {
    /*Data of Graph 008_2 */
    //Color
    var colorGood="#13C959"
    colorNormal ="#FDB900"
    colorBad = "#FF009D"
    //Padding
    paddingLeft = 0
    paddingTop = 30
    paddingBottom = 7
    //Width & Height
    widthColumn = 16
    heightGraph = 187
    //padding axis
    paddingLeftAxisX = 0.4
    paddingTopAxisY =  0,
    paddingBottomAxisY =  39,
    paddingLeftAxisY =  0,
    paddingRightAxisY =  0

    var colors = [colorGood, colorGood, colorNormal, colorNormal, colorBad, colorBad, colorBad, colorBad, colorBad, colorBad, colorBad];
    var colors2 = [colorGood, colorNormal, colorBad, colorBad, colorBad, colorBad];
    var chart_2 = c3.generate({
      bindto: id,
      data: {
        x: "x",
        json: data,

        types: {
          data: 'bar',
        },
        labels: {
          data: false,
        },
        color: function (color, d) {
          return colors2[d.index];
        }
      },
      point: {
        show: false
      },
      padding: {
        left: paddingLeft,
        top: paddingTop,
        bottom: paddingBottom
      },
      interaction: {
        enabled: false
      },
      bar: {
        width: widthColumn,
      },
      size: {
        height: heightGraph
      },
      grid: {
        lines: {
          front: false
        },
        y: {
          show: false,
          lines: function () {
              /*draw line and text in graph*/
                return [
              { value: 50 / 10 * 10, class: 'gridline', text: "", position: "start" },
              { value: 50 / 10 * 9, class: 'grid' },
              { value: 50 / 10 * 8, class: 'grid' },
              { value: 50 / 10 * 7, class: 'grid' },
              { value: 50 / 10 * 6, class: 'grid' },
              { value: 50 / 10 * 5, class: 'gridline', text: "", position: "start" },
              { value: 50 / 10 * 4, class: 'grid' },
              { value: 50 / 10 * 3, class: 'grid' },
              { value: 50 / 10 * 2, class: 'grid' },
              { value: 50 / 10 * 1, class: 'grid', position: "start" },
              { value: 0, class: 'gridline' },
            ];
          },
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        show: false
      },
      axis: {
        x: {
          tick: {
            outer: false
          },
          type: "category",
          padding: { left: paddingLeftAxisX },

        },
        y: {
          tick: {
            outer: false
          },
          max: 50,
          min: 0,
          padding: {
            top: paddingTopAxisY,
            bottom: paddingBottomAxisY,
            left: paddingLeftAxisY,
            right: paddingRightAxisY
           },

        }
      },
      onrendered: function () {
        var axis = d3.selectAll(""+id+" .c3-axis-x");
        axis.selectAll(".c3-axis-x-label").attr("transform", "translate(10, 6)");
        var axisy = d3.selectAll("#g008_1 .c3-axis-y");
        axisy.attr("transform", "translate(0,-55)");
        axisy.selectAll(".c3-axis-y-label").attr("transform", "translate(10, 6)");
        $(""+id+" svg .c3-chart-texts").attr("style","transform:translateY(-10px)")
        $(""+id+" svg .c3-chart-texts .data_label").remove();
        $(""+id+" svg .c3-chart-texts text").attr("class", "data_label").attr("y", "125");
        $(""+id+" svg .c3-event-rect").attr("height","97")
      },
    });
    $(document).ready(function () {
      changeNumberToPercent2();

    });
    function changeNumberToPercent2() {
      var dataChart = chart_2.data.values('data');
      var total = 0;
      for (var i = 0; i < dataChart.length; i++) {
        total += dataChart[i];
      }
      var total_good = dataChart[0];
      var total_mormal = dataChart[1];
      var total_bad1 = dataChart[2] + dataChart[3];
      var total_bad2 = dataChart[4] + dataChart[5];
      //add total for total label
      $(".c-graph-008_list_total-2 .c-graph-008_list_total-1--child p").text(total_good + "票")
      $(".c-graph-008_list_total-2 .c-graph-008_list_total-2--child p").text(total_mormal + "票")
      $(".c-graph-008_list_total-2 .c-graph-008_list_total-3--child p").text(total_bad1 + "票")
      $(".c-graph-008_list_total-2 .c-graph-008_list_total-4--child p").text(total_bad2 + "票")
      // convert to array percent
      chart_2.load({
        columns: [
          ['data',
            round((dataChart[0] / total * 100), 2),
            round((dataChart[1] / total * 100), 2),
            round((dataChart[2] / total * 100), 2),
            round((dataChart[3] / total * 100), 2),
            round((dataChart[4] / total * 100), 2),
            round((dataChart[5] / total * 100), 2),
          ]
        ],
      });
    }
    window.matchMedia("print").addListener(function() {
      if(!$('body').hasClass("is-modal--white-print")){
        chart_2.resize();
      }
    })
  }
 
function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}



