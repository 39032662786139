/**============================================================
*   [print]
*   description : printボタンの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  $(document).on('click','.js-modal--white-print',function() {
    $('body').addClass('is-modal--white-print');

    window.addEventListener('afterprint', (function(x) {
      $('body').removeClass('is-modal--white-print');
      return function f() {
        window.removeEventListener('afterprint');
      }
    }));

    window.print();
  });
});
