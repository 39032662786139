/**============================================================
*   [button]
*   description : ページ下部のボタンがある場合にpaddingクラス付与する処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', function() {
  if(document.querySelector('.c-button-nav')){
    document.querySelector('.l-main').classList.add('l-main--has-bottom-nav');
  };
  if(document.querySelector('.c-nav')){
    document.querySelector('.l-main').classList.add('l-main--has-top-nav');
  };
});
