
// import 'c3';
/**============================================================
 *   [graph003]
 *   description : g003グラフの処理を記述
============================================================*/
var graphId3 = "#g003";
var graphId3_w = "#g003-week";
var graphId3_m = "#g003-month";
var graphBuild_1, graphBuild_2, graphBuild_3;
var dataColorG003 = {
  data1: '#E1DFDB',
  data2: '#1375C9',
  column: 'transparent'
};
$(document).ready(function () {
  displayWindowSize()
  window.addEventListener("resize", displayWindowSize);
});
function displayWindowSize(){
  if($(window).width() > 991){
    if(!$(graphId3).data('c3-chart')){
      buildGraph();
      $(".p-a001-second").css("display","block")
    }
  }else{
    if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
      buildGraph();
    }
    else{
      $(".p-a001-second").css("display","none")
    }
  }
}
 function buildGraph() {
   if ($(graphId3).length > 0 )
     graphBuild_1 = Graph003(graphId3, dataG003_1, dataColorG003,'');
   if ($(graphId3_w).length > 0)
     graphBuild_2 = Graph003(graphId3_w, dataG003_2, dataColorG003,'g3-w');
   if ($(graphId3_m).length > 0)
     graphBuild_3 = Graph003(graphId3_m, dataG003_3, dataColorG003,'g3-m');
   $(graphId3).data('c3-chart', graphBuild_1)
   $(graphId3_w).data('c3-chart', graphBuild_2)
   $(graphId3_m).data('c3-chart', graphBuild_3)
   handleGraphRezie();
 }
 // Resize graph
 function handleGraphRezie() {
   // Resize graph on print
   window.matchMedia("print").addListener(function() {
     if(!$('body').hasClass("is-modal--white-print")){
       if($(graphId3).is(":visible")) {
         graphBuild_1.resize();
       }
       if($(graphId3_w).is(":visible")) {
         graphBuild_2.resize();
       }
       if($(graphId3_m).is(":visible")) {
         graphBuild_3.resize();
       }
     }
   })
 }
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
function Graph003(id, dataG003, dataColorG003, g3Type) {
  /*data of Graph003 */
  //Padding
  var paddingTop = 20
  paddingLeft = 0
  paddingRight = 0
  paddingBottom = 0
  //Width & Height
  widthColumn = 21
  widthLines = 21
  heightGraph = 230
  //padding axis
  paddingTopAxisY = 0
  paddingBottomAxisY = 2
  var jsonData = {
    data1 : dataG003.data1,
    data2: dataG003.data2,
    x: dataG003.x
  };
  if(dataG003.data1Type != 'bar' && dataG003.data2Type != 'bar' ){
    jsonData = {
      data1 : dataG003.data1,
      data2: dataG003.data2,
      column: dataG003.data1,
      x: dataG003.x
    }
  }
  if(dataG003.axisXtype == 'timeseries'){
    formatTick = function (x) {
      if(isValidDate(x)){
        var tickLabelFormat = '';
        switch(dataG003.axisXLabelFormat) {
          case 'year/month':
            tickLabelFormat = x.getFullYear() + '/' + (x.getMonth() + 1);
            break;
          case 'month/day':
            tickLabelFormat = (x.getMonth() + 1) + '/' + x.getDate();
            break;
          case 'year':
            tickLabelFormat = x.getFullYear()
            break;
          default:
            x.getFullYear() + '/' + (x.getMonth() + 1);
        }
        return tickLabelFormat;
      }
    }
  } else {
    formatTick = false
  }
  var chart = c3.generate({
    bindto: id,
    data: {
      x: "x",
      json: jsonData,
      types: {
        data1: dataG003.data1Type,
        data2: dataG003.data2Type,
        column: 'bar'
      },
      hide: ['column'],
      colors: dataColorG003,
      color: function (color, d) {
        if(d.id == 'column'){
          return color
        } else {
          return d.value < 0 ? '#FF4646' : color
        }
      }
    },

      point: {
        show: false
      },
      size: {
        height: heightGraph
      },
      padding: {
        top: paddingTop,
        left: paddingLeft,
        right: paddingRight,
        bottom: paddingBottom
      },
      bar: {
        width: widthColumn,
      },
      lines: {
        width: widthLines,
      },
      grid: {
        lines: {
          front: false
        },
        y: {
          show: false,
          lines: function () {
            /*draw line and text in graph*/
            return [
              { value: -100 / 4 * 4, class: 'gridfoot2', text: "-100", position: "start" },
              { value: -100 / 4 * 3, class: 'grid', },
              { value: -100 / 4 * 2, class: 'grid' },
              { value: -100 / 4 * 1, class: 'grid' },
              { value: 0, class: 'gridline gridline0', text: "0", position: "start" },
              { value: 100 / 4 * 1, class: 'grid' },
              { value: 100 / 4 * 2, class: 'grid' },
              { value: 100 / 4 * 3, class: 'grid' },
              { value: 100 / 4 * 4, class: 'grid' }
            ];
          },
        }
      },
      legend: {
        show: false,
      },
      axis: {
        x: {
          tick: {
            outer: false,
            multiline: false,
            culling: {
              max: dataG003.axisXcullingMax
            },
            format: formatTick
          },
          type: dataG003.axisXtype,
          label: {
            text: '100',
            position: "outer-left",
          }
        },
        y: {
          tick: {
            outer: false
          },
          max: 100,
          min: -100,
          padding: { top: paddingTopAxisY, bottom: paddingBottomAxisY },
          label: {
            text: '-100',
            position: "inner-bottom",
          }
        }
      },
      tooltip: {
        //custom tooltip of Graph
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          var $$ = this,
            titleFormat = defaultTitleFormat,
            nameFormat = function (name) {
              return name;
            },
            valueFormat = defaultValueFormat,
            text, i, title, value, name, bgcolor;
          for (i = 0; i < d.length - 1; i++) {
            if (!(d[i] && (d[i].value || d[i].value === 0))) {
              continue;
            }
            if (!text) {
              title = titleFormat ? titleFormat(d[i].x) : d[i].x;
              if(dataG003.axisXtype == 'timeseries'){
                title = d[i].x.getFullYear() + '/' + (d[i].x.getMonth() + 1) + '/' + d[i].x.getDate();
              }
              text = "<table class='c3-tooltip c3-tooltip-container'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
            }
            value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
            if (value !== undefined) {
              name = nameFormat(d[i].name, d[i].ratio, d[i].id, d[i].index);
              bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

            text += "<tr class='" + d[i].id + "'>";
            text += "<td class='value'>" + value + "</td>";
            text += "</tr>";
          }
        }
        return text + "</table>";
      }
    },
    onrendered: function () {
      var graphWidth = parseInt(d3.select(id + ' .c3-event-rect').attr('width'))
      var axis = d3.selectAll("" + id + " .c3-axis-x");
      axis.attr("transform", "translate(0,0)");
      var tick = axis.selectAll(".tick");
      var tickText = axis.selectAll(".tick text");
      var tickShow = []
      tickText.filter(function(d, i) {
        if(d3.select(this).style('display') == 'block'){
          tickShow.push(d3.select(this))
        }
      });
      if(tickShow.length > 3) {
        // Detect First Tick
        var firstTransformValue = d3.select(tickShow[0].node().parentNode).attr('transform')
        var firstTranslateX = firstTransformValue.replace(/[^\d,.]/g, '').split(',')[0];

        var secondTransformValue = d3.select(tickShow[1].node().parentNode).attr('transform')
        var secondTranslateX = parseInt(secondTransformValue.replace(/[^\d,.]/g, '').split(',')[0])

        var lastTransformNode = d3.select(tickShow[tickShow.length / 2 - 1].node().parentNode)
        var lastTransformValue = lastTransformNode.attr('transform')
        var lastTranslateX = parseInt(lastTransformValue.replace(/[^\d,.]/g, '').split(',')[0])

        var nextLastTransformNode = d3.select(tickShow[tickShow.length / 2 - 2].node().parentNode).attr('transform')
        var nextLastTranslateX = parseInt(nextLastTransformNode.replace(/[^\d,.]/g, '').split(',')[0]);

        var tickWidth = tickShow[0].node().getBoundingClientRect().width / 2;
        var lastTickWidth = tickShow[tickShow.length / 2 - 1].node().getBoundingClientRect().width;
        var nextLastTickWidth = tickShow[tickShow.length / 2 - 2].node().getBoundingClientRect().width;
        // console.log(lastTranslateX, lastTickWidth, graphWidth, parseInt(lastTranslateX) + lastTickWidth)
        //Move first tick to x=0
        if (firstTranslateX < tickWidth)
          tickShow[0].select('tspan').attr('dx', (tickWidth - firstTranslateX))
        //Hide First tick when this tick over the next tick
        if (tickWidth > secondTranslateX)
          tickShow[0].select('tspan').attr('style', 'display: none')
        // Move the last tick to x = graph width
        if ((lastTranslateX + lastTickWidth / 2) > graphWidth)
          lastTransformNode.attr('transform', 'translate(' + (graphWidth - lastTickWidth / 2) + ', 0)')
        //Hide Last tick when this tick over the next last tick
        if ((nextLastTranslateX + nextLastTickWidth / 2 + lastTickWidth) > graphWidth)
          tickShow[tickShow.length / 2 - 1].select('tspan').attr('style', 'display: none')
      }

      axis.selectAll(".c3-axis-x-label").attr("transform", "translate(-5,-17)");
      var axisy = d3.selectAll("" + id + " .gridline");
      axisy.selectAll("text").attr("transform", "translate(0,19)");
      $('.c-graph-003 > p').css('visibility', 'visible')
    },
    oninit: function () {
      var bars = d3.select(id).select(".c3-chart-bars").node();
      bars.parentNode.appendChild(bars);
    }
  });
  return chart;
}
