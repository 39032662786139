/**============================================================
*   [graph-nps-score]
*   description : NPSスコア推移グラフ描画用の処理を記述
============================================================*/
(function () {

  if(document.getElementById('an004-anq')){
    const an004AnqObj = [
      {
        name: '全体',
        sampleNum: 555,
        scoreArr: 0,
        criticismPersonArr: '5%',
        neutralPersonArr: '5%',
        recommendedPersonArr: '5%'
      },
      {
        name: '五反田店',
        sampleNum: 337,
        scoreArr: +24.3,
        criticismPersonArr: '30%',
        neutralPersonArr: '30%',
        recommendedPersonArr: '30%'
      },
      {
        name: '大崎店',
        sampleNum: 337,
        scoreArr: +100,
        criticismPersonArr: '30%',
        neutralPersonArr: '30%',
        recommendedPersonArr: '30%'
      }
    ];

    //DOM
    // const an004Row = ref(null);
    // const scoreCell = ref(null);

    const an004Row = document.querySelectorAll('[data-ref*="an004Row"]');
    const scoreCell = document.querySelectorAll('[data-ref="scoreCell"]');

    an004Row.forEach(function(data,$key){
      //スコア要素
      let score = scoreCell[$key].childNodes.item(0).nodeValue.trim();
      console.log(score);

      //[スコア文字色] 50以上、-50以下は反転
      if(Math.abs(score) >= 50) scoreCell[$key].style.color = '#ffffff';

      // グリッドの背景色設定
      if(Math.sign(score) === 1){
        //[スコアがプラスの場合]
        scoreCell[$key].style.backgroundColor = 'rgba(19,117,201,' + Math.abs(score) / 100 + ')';

      }else if(Math.sign(score) === 0){
        //[スコアが0の場合]
        scoreCell[$key].style.backgroundColor = 'rgba(219,219,219,1)';

      }else{
        //[スコアがマイナスの場合]
        scoreCell[$key].style.backgroundColor = 'rgba(201,0,63,' + Math.abs(score) / 100 + ')';
      }

    })
  }

})();
